import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Toolbar from './navigation/toolbar'
import Navbar from './navigation/navbar'
import Offcanvas from './navigation/offcanvas'

const Header = () => {
	const data = useStaticQuery(graphql`
		query {
			allCategory: allSanityCategory(
				filter: { root: { eq: true } }
				sort: { fields: [title], order: [ASC] }
			) {
				edges {
					node {
						slug {
							current
						}
						title
						children: subcategories {
							slug {
								current
							}
							title
							children: subcategories {
								slug {
									current
								}
								title
								children: subcategories {
									slug {
										current
									}
									title
								}
							}
						}
					}
				}
			}

			allSanityManufacturer {
				edges {
					node {
						slug {
							current
						}
						title
					}
				}
			}
		}
	`)

	const categories = data.allCategory.edges.map(({ node }) => node)
	const manufacturers = data.allSanityManufacturer.edges.map(({ node }) => node)

	return (
		<header>
			<Toolbar />
			<Navbar categories={categories} manufacturers={manufacturers} />
			<Offcanvas categories={categories} manufacturers={manufacturers} />
		</header>
	)
}

export default Header
