import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import slugify from 'slugify'
import { getSlug } from '../../utils/url'
// TODO: uk-active

const NavItemOffcanvas = ({
	label,
	url,
	icon,
	children,
	root = false,
	clickUrl = url,
}) => {
	let navElement, innerElement

	if (children && children.length > 0) {
		innerElement = (
			<ul className="uk-nav-sub">
				{children.map((child) => {
					return (
						<NavItemOffcanvas
							key={getSlug(child)}
							label={child.title}
							url={`${url}/${slugify(child.title, { lower: true })}`}
							children={child.children}
						/>
					)
				})}
			</ul>
		)

		// note: Elemento pai e seus filhos
		navElement = (
			<li className="uk-parent">
				<Link to={`${clickUrl}`}>
					{icon && <span uk-icon={icon}></span>}
					<span>{label}</span>
				</Link>
				{innerElement}
			</li>
		)
	} else {
		// note: Elemento sem filhos
		navElement = (
			<li>
				<Link to={`${clickUrl}`}>
					{icon && <span uk-icon={icon}></span>}
					<span>{label}</span>
				</Link>
			</li>
		)
	}

	return navElement
}

NavItemOffcanvas.propTypes = {
	label: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	clickUrl: PropTypes.string, // hack: URL de destino ao clicar no item, geralmente igual à url passada
	icon: PropTypes.string,
	children: PropTypes.array,
	root: PropTypes.bool,
}

export default NavItemOffcanvas
