import React, { useState, createRef } from 'react'
import { addSubscriber } from '../utils/email'

const Newsletter = () => {
	const [email, setEmail] = useState('')
	const submitButtonRef = createRef()

	function handleSubmit(e) {
		e.preventDefault()
		addSubscriber(email)
			.then(() => {
				submitButtonRef.current.setAttribute('uk-icon', 'check')
				submitButtonRef.current.disabled = true
			})
			.catch(e => {
				alert(
					'Desculpe, houve um erro durante sua inscrição, tente novamente mais tarde'
				)
				console.error(e)
			})
	}

	return (
		<div>
			<h3 className="uk-text-uppercase">Inscreva-se!</h3>
			<p>
				Receba nossos e-mails exclusivos e tenha a chance de encontrar o produto
				pefeito para você.
			</p>

			<form className="tm-form-newsletter" onSubmit={handleSubmit}>
				<div className="uk-margin">
					<div className="uk-inline">
						<button
							type="submit"
							className="uk-form-icon uk-form-icon-flip uk-background-primary uk-light"
							uk-icon="icon: sign-in"
							ref={submitButtonRef}
						></button>
						<input
							className="uk-input uk-form-width-large"
							type="email"
							placeholder="Digite seu email"
							id="email"
							required
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Newsletter
