import React from 'react'
import Newsletter from './newsletter'
import PaymentMethods from './payment-methods'

const Footer = () => (
	<footer
		className="uk-position-relative uk-position-bottom uk-padding uk-margin-remove uk-background-secondary"
		data-uk-grid
	>
		<div className="uk-width-1-1@s uk-width-1-3@m">
			<ul className="uk-list uk-link-text uk-light uk-text-center">
				<li>&copy; {new Date().getFullYear()} ISTM Instrumentação Ltda.</li>
				<li>CNPJ: 14.205.151/0001-90</li>
				<li>Inscrição Estadual: 79.481.190</li>
				<li>
					<span className="uk-margin-small-right" uk-icon="location"></span>
					<a href="https://goo.gl/maps/fLJJzFQRGCx">
						Rua Pacaembu, 62 - Realengo RJ
					</a>
				</li>
			</ul>
		</div>

		<div className="uk-width-1-1@s uk-width-1-3@m">
			<ul className="uk-list uk-link-text uk-light uk-text-center">
				<li>
					<span className="uk-margin-small-right" uk-icon="mail"></span>
					<a href="mailto:mailto:istm@istm.com.br">istm@istm.com.br</a>
				</li>
				<li>
					<span className="uk-margin-small-right" uk-icon="receiver"></span>+ 55
					21 3462 9154
				</li>
				<li>
					<span className="uk-margin-small-right" uk-icon="whatsapp"></span>
					(21) 4145 5366
				</li>
				<li>
					<span className="uk-margin-small-right" uk-icon="whatsapp"></span>
					(21) 98479 9154
				</li>
			</ul>
		</div>

		<div className="uk-width-1-1@s uk-width-1-3@m">
			<div className="uk-child-width-1-1 uk-light" data-uk-grid>
				<Newsletter />
			</div>
		</div>

		{/* <div className="uk-width-1-1@s uk-width-1-3@m uk-light">
			<h3 className="uk-text-uppercase">
				Aceitamos Cartão de Crédito / Débito
			</h3>
			<PaymentMethods />
		</div> */}
	</footer>
)

export default Footer
