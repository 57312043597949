/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

import '../scss/main.scss'

const Layout = ({ children }) => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			try {
				window.UIkit = require('uikit/dist/js/uikit')
				window.Icons = require('uikit/dist/js/uikit-icons')
				window.UIkit.use(window.Icons)
			} catch (e) {
				console.error(e)
			}
		}
	}, [])

	return (
		<>
			<Header />
			<section className="uk-section uk-section-small">
				<div id="content" className="uk-container uk-text-justify">
					{children}
				</div>
			</section>

			<Footer />

			{/* eslint-disable-next-line */}
			<a
				href="#"
				uk-totop="true"
				uk-scroll="true"
				className="uk-position-fixed uk-position-medium uk-position-bottom-right"
			></a>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
