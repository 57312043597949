import React from 'react'
import { Link } from 'gatsby'
import algoliasearch from 'algoliasearch/lite'
import {
	InstantSearch,
	connectStateResults,
	connectSearchBox,
	Hits,
	Configure,
	Highlight,
	PoweredBy,
} from 'react-instantsearch-dom'
import { getProductURL, getSlug } from '../utils/url'

const searchClient = algoliasearch(
	process.env.GATSBY_ALGOLIA_APP_ID,
	process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Product = ({ hit }) => {
	return (
		<li>
			<Link to={getProductURL(getSlug(hit))}>
				<div className="uk-margin-small" data-uk-grid>
					<div className="uk-width-auto">
						<img
							src={hit.imageUrl}
							alt={hit.title}
							height={64}
							width={64}
						></img>
					</div>
					<div className="uk-width-expand">
						<span style={{ display: 'block' }}>
							<Highlight attribute="title" hit={hit} />
						</span>
						<span className="uk-text-muted">{hit.sku}</span>
					</div>
				</div>
			</Link>
		</li>
	)
}

const Results = connectStateResults(({ searchState }) =>
	searchState && searchState.query ? (
		<div className="uk-list uk-list-large">
			<Hits hitComponent={Product} />
			<PoweredBy />
		</div>
	) : //<div>No query</div>
	null
)

const SearchBox = ({ currentRefinement, refine }) => (
	<input
		type="search"
		className="uk-search-input"
		placeholder="Buscar"
		value={currentRefinement}
		onChange={(event) => refine(event.currentTarget.value)}
		autoFocus
	/>
)

const CustomSearchBox = connectSearchBox(SearchBox)

export const Searchbar = ({ currentRefinement }) => (
	<InstantSearch searchClient={searchClient} indexName="Products">
		<CustomSearchBox currentRefinement={currentRefinement} />
		<Results />
		<Configure hitsPerPage={5} />
	</InstantSearch>
)

export default Searchbar
