exports.addSubscriber = email => {
	const params = {
		'subscriber[email]': email,
	}

	const encodedParams = Object.keys(params)
		.map(function(key) {
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')

	return fetch(process.env.GATSBY_API_EMAIL_ADD_SUBSCRIBER, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		mode: 'no-cors',
		body: encodedParams,
	})
}

exports.sendEmail = params =>
	fetch(process.env.GATSBY_API_EMAIL_SEND, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(params),
	})
