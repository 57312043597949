import React from 'react'
import { Link } from 'gatsby'

import NavItem from './nav-item'
import { Searchbar } from '../search-form'
import Logo from '../../../static/logo.svg'

const Navbar = ({ categories, manufacturers }) => {
	return (
		<nav className="uk-navbar-container tm-navbar" data-uk-navbar>
			<div className="uk-navbar-left nav-overlay">
				<a
					className="uk-navbar-toggle uk-hidden@m"
					href="#"
					uk-toggle="target: #offcanvas-nav"
					data-uk-navbar-toggle-icon
				></a>
				<Link to="/" className="uk-navbar-item">
					<img
						className="tm-logo"
						src={Logo}
						width={192}
						height={192}
						alt="Logo ISTM"
						data-uk-svg
					/>
				</Link>
			</div>

			<div className="uk-navbar-center uk-visible@m nav-overlay">
				<ul className="uk-navbar-nav">
					<NavItem label="Início" url="/" icon="home" />
					<NavItem label="Sobre" url="/sobre" icon="info" />

					<NavItem
						label="Fabricantes"
						url="/fabricante"
						clickUrl="/fabricante/"
						icon="list"
						children={manufacturers}
						root={true}
					/>

					<NavItem
						label="Categorias"
						url="/categoria"
						clickUrl="/produto/"
						icon="list"
						children={categories}
						root={true}
					/>

					<NavItem label="Contato" url="/contato" icon="mail" />
				</ul>
			</div>

			<div className="uk-navbar-right uk-padding nav-overlay">
				<a
					className="uk-navbar-toggle"
					data-uk-search-icon
					data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
					href="#"
				></a>
			</div>
			<div
				className="nav-overlay uk-navbar-left uk-flex-1 uk-box-shadow-medium"
				hidden
			>
				<div className="uk-navbar-item uk-width-expand">
					<form className="uk-search uk-search-navbar uk-width-1-1">
						<Searchbar />
					</form>
				</div>

				<a
					className="uk-navbar-toggle uk-icon-button"
					data-uk-close
					data-uk-toggle="target: .nav-overlay; animation: uk-animation-fade"
					href="#"
				></a>
			</div>
		</nav>
	)
}

export default Navbar
