import React from 'react'

import NavItemOffcanvas from './nav-item-offcanvas'

const Offcanvas = ({ categories, manufacturers }) => {
	return (
		<div id="offcanvas-nav" data-uk-offcanvas>
			<div className="uk-offcanvas-bar">
				<ul className="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
					<NavItemOffcanvas label="Início" url="/" icon="home" />
					<NavItemOffcanvas label="Sobre" url="/sobre" icon="info" />

					<NavItemOffcanvas
						label="Fabricantes"
						url="/fabricante"
						icon="list"
						children={manufacturers}
						root={true}
					/>

					<NavItemOffcanvas
						label="Categorias"
						url="/categoria"
						icon="list"
						children={categories}
						root={true}
					/>

					<NavItemOffcanvas label="Contato" url="/contato" icon="mail" />
				</ul>
			</div>
		</div>
	)
}

export default Offcanvas
