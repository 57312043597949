import React from 'react'

const Toolbar = () => (
	<nav className="uk-navbar-container uk-light uk-visible@m tm-toolbar">
		<div className=" uk-container uk-navbar" data-uk-navbar>
			<div className="uk-navbar-left">
				<ul className="uk-navbar-nav">
					<li className="uk-navbar-item">
						<span className="uk-margin-small-right" uk-icon="receiver"></span>
						(21) 3462 9154
					</li>
					<li className="uk-navbar-item">
						<span className="uk-margin-small-right" uk-icon="whatsapp"></span>
						(21) 4145 5366
					</li>
					<li className="uk-navbar-item">
						<span className="uk-margin-small-right" uk-icon="whatsapp"></span>
						(21) 98479 9154
					</li>
					<li className="uk-navbar-item">
						<span className="uk-margin-small-right" uk-icon="mail"></span>
						istm@istm.com.br
					</li>
				</ul>
			</div>
		</div>
	</nav>
)

export default Toolbar
