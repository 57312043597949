import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import slugify from 'slugify'
import { getSlug } from '../../utils/url'
// TODO: uk-active

const NavItem = ({
	label,
	url,
	icon,
	children,
	root = false,
	clickUrl = url,
}) => {
	const renderArrow = children && children.length > 0 && !root
	let navElement, dropdownElement, innerElement

	if (children && children.length > 0) {
		innerElement = (
			<ul className="uk-nav uk-dropdown-nav">
				{children.map((child) => {
					return (
						<NavItem
							key={getSlug(child)}
							label={child.title}
							url={`${url}/${slugify(child.title, { lower: true })}`}
							children={child.children}
						/>
					)
				})}
			</ul>
		)

		dropdownElement = (
			<div
				uk-dropdown={`pos: ${
					root ? 'bottom-center' : 'right-center'
				}; delay-hide: 100`}
			>
				{innerElement}
			</div>
		)
		// note: Elemento pai e seus filhos
		navElement = (
			<li>
				<Link to={`${clickUrl}`}>
					{icon && <span uk-icon={icon}></span>}
					<span>{label}</span>
					{renderArrow && <span uk-icon="chevron-right"></span>}
				</Link>
				{dropdownElement}
			</li>
		)
	} else {
		// note: Elemento sem filhos
		navElement = (
			<li>
				<Link to={`${clickUrl}`}>
					{icon && <span uk-icon={icon}></span>}
					<span>{label}</span>
				</Link>
			</li>
		)
	}

	return navElement
}

NavItem.propTypes = {
	label: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	clickUrl: PropTypes.string, // hack: URL de destino ao clicar no item, geralmente igual à url passada
	icon: PropTypes.string,
	children: PropTypes.array,
	root: PropTypes.bool,
}

export default NavItem
