exports.getProductURL = (slug) => `/produto/${slug}`
exports.getCategoryURL = (slug) => `/categoria/${slug}`
exports.getManufacturerURL = (slug) => `/fabricante/${slug}`
exports.getListPageURL = (slug) => `/lista/${slug}`

exports.getAPIURL = () => process.env.GATSBY_API_URL
exports.getFileURL = (file) =>
	`${process.env.GATSBY_API_URL}${file ? file.url : file}`

exports.getSlug = (object) => {
	if (!object) return ''

	// object with sanity slug
	if (object.slug) {
		if (object.slug.current) {
			return object.slug.current
		}

		return object.slug
	}

	// the slug property itself
	if (object.current) {
		return object.current
	}

	// object given actually is the slug string itself
	if (typeof object === 'string') {
		return object
	}

	return ''
}
